<template>
  <div>
    <v-card class="px-5 mb-10">
      <v-row>
        <v-col cols="12" md="6">
          <h3 class="primary--text">SELAMAT DATANG - {{ session.nama }}</h3>
        </v-col>

        <v-col cols="12" md="6" class="text-right">
          <v-icon left> mdi-calendar-blank </v-icon>
          {{ Ftgl(Date.now()) }}
        </v-col>
      </v-row>
    </v-card>

    <div class="mb-5" v-if="jumjab">
      <v-row>
        <v-col cols="12" md="4">
          <v-card color="primary" class="pa-5" dark>
            <v-row dense>
              <v-col cols="8">
                <div><h1>TOTAL</h1></div>
                <div class="font-weight-black" style="font-size: 50px">
                  {{ jumjab.total }}
                </div>
              </v-col>

              <v-col cols="4" class="text-center my-auto">
                <v-icon size="80">mdi-chair-rolling</v-icon>
              </v-col>
            </v-row>
          </v-card>

          <v-card color="green" class="pa-5 my-5" dark>
            <v-row dense>
              <v-col cols="8">
                <div><h1>TERISI</h1></div>
                <div class="font-weight-black" style="font-size: 50px">
                  {{ jumjab.terisi }}
                </div>
              </v-col>

              <v-col cols="4" class="text-center my-auto">
                <v-icon size="80">mdi-chair-rolling</v-icon>
              </v-col>
            </v-row>
          </v-card>

          <v-card color="error" class="pa-5" dark>
            <v-row dense>
              <v-col cols="8">
                <div><h1>LOWONG</h1></div>
                <div class="font-weight-black" style="font-size: 50px">
                  {{ jumjab.lowong }}
                </div>
              </v-col>

              <v-col cols="4" class="text-center my-auto">
                <v-icon size="80">mdi-chair-rolling</v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="8">
          <v-card class="pa-5">
            <v-data-table
              :headers="headers"
              :items="namjab"
              :loading="loading"
              :items-per-page="-1"
              hide-default-footer
            >
              <template v-slot:[`item.nomor`]="{ item }">
                {{
                  namjab
                    .map(function (x) {
                      return x.jabatan_nama;
                    })
                    .indexOf(item.jabatan_nama) + 1
                }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-card class="pl-5 pt-5 pb-4">
      <organization-chart
        :datasource="ds"
        :zoom="true"
        :pan="true"
        @node-click="(nodeData) => myFunction(nodeData)"
      >
        <template slot-scope="{ nodeData }">
          <v-card class="px-2" outlined>
            <small>
              {{ nodeData.title }}
            </small>
          </v-card>
        </template>
      </organization-chart>
    </v-card>

    <!-- <v-card class="pa-5 mt-5" style="overflow: auto;">
      <OrgChart :value="map" :collapsible="true" :collapsedKeys="collapsedKeys">
        <template #default="slotProps">
          <span>{{ slotProps.node.title }}</span>
        </template>
      </OrgChart>
    </v-card> -->
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import OrganizationChart from "vue-organization-chart";
// import OrgChart from "primevue/organizationchart";

export default {
  components: {
    OrganizationChart,
    // OrgChart,
  },

  data: () => ({
    token: Cookie.get("token"),
    ldata: true,
    session: "",

    ds: {},
    map: {},
    jumjab: {},
    namjab: [],

    collapsedKeys: {
      0: true,
    },

    headers: [
      {
        text: "No",
        value: "nomor",
        align: "center",
        width: "50px",
        sortable: false,
      },
      { text: "Nama", align: "start", value: "jabatan_nama" },
      {
        text: "Lowong",
        value: "lowong",
        align: "center",
        sortable: false,
      },
      {
        text: "Terisi",
        value: "terisi",
        align: "center",
        sortable: false,
      },
      {
        text: "Total",
        value: "jumlah",
        align: "center",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getData();
    this.get_jumjab();
    this.get_namjab();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_API + "unor/all";
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.ldata = false;
          this.ds = this.myFunction(res.data.mapData[0]);
          this.map = this.mapping(res.data.mapData[0]);
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log("Error", error.message);
        });
    },

    Ftgl(tgl) {
      const date = new Date(tgl);
      const dateTimeFormat = new Intl.DateTimeFormat("id", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
      });
      const formatted = dateTimeFormat.formatToParts(date);
      return (
        formatted[0].value + " " + formatted[2].value + " " + formatted[4].value
      );
    },

    myFunction(value) {
      const files = JSON.stringify(value);
      var parsed = JSON.parse(files, function (k, v) {
        if (k === "nama") {
          this.title = v;
        } else if (k === "bawahan") {
          this.children = v;
        } else {
          return v;
        }
      });

      return parsed;
    },

    mapping(value) {
      const files = JSON.stringify(value);
      var parsed = JSON.parse(files, function (k, v) {
        if (k === "nama") {
          this.title = v;
        } else if (k === "bawahan") {
          this.children = v;
        } else if (k === "id") {
          this.key = v;
        } else {
          return v;
        }
      });

      return parsed;
    },

    get_jumjab() {
      const url = process.env.VUE_APP_API + "statistik/jumjab";
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.ldata = false;
          this.jumjab = res.data.mapData;
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log("Error", error.message);
        });
    },

    get_namjab() {
      this.ldata = true;

      const url = process.env.VUE_APP_API + "statistik/namjab";
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.ldata = false;
          this.namjab = res.data.mapData;
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log("Error", error.message);
        });
    },
  },
};
</script>
